export const pasteContentIntercept = (editor, callback) => {

      editor.editing.view.document.on("paste", (evt, data) => {
        
        data.stopPropagation();
        data.preventDefault();

        let clipboardData, pastedDataText;

        clipboardData = data.domEvent.clipboardData || window.clipboardData;
        
        pastedDataText = clipboardData.getData('text/plain');
        pastedDataText = pastedDataText.replace(/(\r\n|\n\r|\r|\n)/g, '<br>');
        pastedDataText = pastedDataText.split('<br>');
        pastedDataText = "<p>" + pastedDataText.join("</p><p>") + "</p>";
        
        const viewFragment = editor.data.processor.toView( pastedDataText );
        const modelFragment = editor.data.toModel( viewFragment );
        editor.model.insertContent( modelFragment );

        callback();
        
        evt.stop();
      });


};