<template>
  <button @click="triggerStart" :class="{ started: started }">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="-25 52 66 66"
      enable-background="new -25 52 66 66"
      xml:space="preserve"
      class="docs-mic-svg-icon"
    >
      <path
        class="docs-mic-svg-icon-path"
        d="m8 92.895c5.224 0 10-4.243 10-9.474v-18.947c0-5.237-4.776-9.474-10-9.474-5.226 0-10 4.237-10 9.474v18.947c0 5.23 4.774 9.474 10 9.474z"
      ></path>
      <path
        class="docs-mic-svg-icon-path"
        d="m24.104 83c0 9.474-7.383 16.525-16.104 16.525-8.727 0-16.105-7.052-16.105-16.525h-5.895c0 10.784 8.643 20.103 19 21.635v10.36h6v-10.365c10.355-1.53 19-10.856 19-21.64h-5.896z"
      ></path>
    </svg>
  </button>
</template>

<script>
export default {
  data() {
    return {
      recognition: null,
      runtimeTranscription: "",
      transcription: [],
      started: false,
    };
  },
  props: {
    editor: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    triggerStart() {
      this.editor.focus();
      if (!this.started) {
        if (this.recognition && !this.started) {
          this.startRecognition();
        }
      } else {
        this.stopRecognition();
      }
    },
    startRecognition() {
      this.checkApi();
      this.recognition.start();
      this.started = true;
    },
    stopRecognition() {
      this.recognition.stop();
      this.recognition = null;
      this.started = false;
    },
    checkApi() {
      try {
        window.SpeechRecognition =
          window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!window.SpeechRecognition && "development" !== "production") {
          throw new Error(
            "Speech Recognition does not exist on this browser. Use Chrome or Firefox"
          );
        }
        if (!window.SpeechRecognition) {
          console.log("No Speech Recognition");
          return;
        }

        this.recognition = new window.SpeechRecognition();
        this.recognition.lang = this.lang;
        this.recognition.interimResults = true;
        this.recognition.addEventListener("result", (event) => {
          const text = Array.from(event.results)
            .map((result) => result[0])
            .map((result) => result.transcript)
            .join("");

          let result = text.replace(/(?:(?:^|[.?!])\W*)[a-z]/g, (i) =>
            i.toUpperCase()
          );

          const commands = {
            "new line": () => {
              result = "\n";
            },
          };

          const transcript = event.results[
            event.results.length - 1
          ][0].transcript
            .toLowerCase()
            .trim();

          for (let command in commands) {
            if (transcript.indexOf(command) === 0) {
              if (transcript[command.length] === undefined) {
                commands[command]();
              } else {
                const param = transcript
                  .substring(command.length, transcript.length)
                  .trim();
                commands[command](param);
              }
            }
          }

          this.runtimeTranscription = result;
        });
        this.recognition.addEventListener("end", () => {
          if (this.runtimeTranscription !== "") {
            this.transcription.push(this.runtimeTranscription);
            this.$emit("onTranscriptionEnd", {
              transcription: this.transcription,
              lastSentence: this.runtimeTranscription,
            });
          }
          this.runtimeTranscription = "";
          if (this.started) {
            this.recognition.start();
          }
        });
        this.recognition.onstart = function () {
          console.log("Speech recognition service has started");
        };
        this.$store.commit("SET_WEBSPEECH", this.recognition);
      } catch (e) {
        console.error(e);
        this.recognition.stop();
        this.started = false;
      }
    },
  },
  mounted() {
    this.checkApi();
  },
};
</script>

<style scoped>
button {
  padding: 10px;
  background-color: transparent;
  background: none;
  border: 0;
  width: 60px;
  height: 70px;
  background: #fff;
  position: absolute;
  top: 100px;
  left: -30px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
button.started {
  color: red;
}
button.started > svg {
  fill: red;
}
button:after {
  content: "Click Here";
  font-size: 7px;
  line-height: 1;
  word-break: normal;
  letter-spacing: 1px;
  white-space: nowrap;
  position: absolute;
  left: 5px;
  bottom: 5px;
  text-transform: uppercase;
}
svg {
  vertical-align: 10px;
}
</style>