<template>
  <div class="container scrolled">
    <CloseModalButton @close="closeModal" />
    <PicturesListContainer :props="props" :item="item" @close="closeModal" />
  </div>
</template>

<script>
import CloseModalButton from "./CloseModalButton.vue";
import PicturesListContainer from "./PicturesListContainer.vue";

export default {
  components: { CloseModalButton, PicturesListContainer },
  data() {
    return {
      selected_picture: "",
      show_pictures: true,
    };
  },
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    meta_key: {
      type: String,
      default: "image1_id",
    },
    selected_page: {
      type: Object,
      default() {
        return {};
      },
    },
    selected_index: {
      type: Number,
      default: 0,
    },
    editor: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {},
  methods: {
    closeModal() {
      this.$modal.hide(`pictures-list`);
    },
  },
  mounted() {},
};
</script>
