import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import Command from "@ckeditor/ckeditor5-core/src/command";
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

const WEB_SPEECH = "webSpeech";

class WebSpeechCommand extends Command {
  execute() {
    return true;
  }
}

export default class WebSpeechAdapter extends Plugin {
  /**
   * @inheritDoc
   */
  constructor(editor) {
    super(editor);

    
  }

  /**
   * @inheritDoc
   */
  static get pluginName() {
    return "WebSpeechAdapter";
  }

  imageIcon() {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" preserveAspectRatio="none" xmlns:v="https://vecta.io/nano"><path fill-rule="evenodd" d="M9.165 14.895c-2.838-.476-5-2.944-5-5.917h1.5a4.5 4.5 0 1 0 9 0h1.5c0 2.973-2.162 5.441-5 5.917v3.083h-2zm1-2.917c-1.383 0-2.5-1.117-2.5-2.5v-5c0-1.383 1.117-2.5 2.5-2.5s2.5 1.117 2.5 2.5l-.008 5a2.49 2.49 0 0 1-2.492 2.5z"/></svg>`;
  }

  init() {
    const editor = this.editor;
    const t = editor.t;

    editor.commands.add(WEB_SPEECH, new WebSpeechCommand(editor));
    const command = editor.commands.get(WEB_SPEECH);

    editor.ui.componentFactory.add(WEB_SPEECH, (locale) => {

      const view = new ButtonView( locale );

      view.set( {
          label: t('Web Speech'),
          icon: this.imageIcon(),
          tooltip: true,
      } );

      view.bind( 'isEnabled' ).to( command, 'isEnabled' );

      view.on( 'execute', (props) => {

          command.execute(props);

      });

      return view;

    });
  }

  destroy() {
    // Removes all listeners added with this.listenTo();
    this.stopListening();
  }
}
