import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Command from '@ckeditor/ckeditor5-core/src/command';

class FilestackUploadCommand extends Command {

    
    refresh() {
        const model = this.editor.model;
        const doc = model.document;
        const selected = model.getSelectedContent( doc.selection );

        this.isEnabled = selected.isEmpty;
        
    }

    execute( callback ) {
        callback();
    }
}

export default class FilestackUploadAdapter extends Plugin {
    /**
	 * @inheritDoc
	 */

	static get pluginName() {
		return 'FilestackUploadAdapter';
	}

    init() {
        
        const editor = this.editor;
        const t = editor.t;

        editor.commands.add( 'filestackUpload', new FilestackUploadCommand( editor ) );

        editor.ui.componentFactory.add( 'filestack', locale => {
            
            const command = editor.commands.get( 'filestackUpload' );
            
            const view = new ButtonView( locale );

            view.set( {
                label: t('Filestack Image Uploader'),
                icon: imageIcon,
                tooltip: true,
            } );

            view.bind( 'isOn' ).to( command, 'isOn' );

            view.on( 'execute', () => {

                command.execute(() => {});

            } );

            return view;
        } );
    }

    destroy() {
        // Removes all listeners added with this.listenTo();
        this.stopListening();
    }
}