<template>
  <div class="container">
    <CloseModalButton @close="closeModal" />
    <div class="row">
      <div class="col-md-12">
        <div class="card mt-3">
          <div class="card-header">Default Font and Font Size</div>
          <div class="card-body">
            <div class="form-group row">
              <label for="font" class="col-sm-4 col-form-label">Font</label>
              <div class="col-sm-8">
                <select
                  class="form-control"
                  id="font"
                  v-model="currentFont"
                  :style="{ fontFamily: currentFont }"
                >
                  <option
                    v-for="font in fonts"
                    :key="font"
                    :style="{ fontFamily: font }"
                  >
                    {{ font }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label for="font" class="col-sm-4 col-form-label"
                >Font Size</label
              >
              <div class="col-sm-8">
                <select
                  class="form-control"
                  id="fontSizes"
                  v-model="currentSize"
                  :style="{ fontFamily: currentFont }"
                >
                  <option v-for="size in fontSizes" :key="size" :value="size">
                    {{ size }}px
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CloseModalButton from "./CloseModalButton.vue";
import { fonts, fontSizes } from "../data/editor-config.js";

export default {
  components: { CloseModalButton },
  data() {
    return {
      fontFamily: "Lato",
      fontSize: 14,
    };
  },
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["getDefaults"]),
    isChanged() {
      return (
        this.currentFont === this.getDefaults.default_font_family &&
        this.currentSize === parseInt(this.getDefaults.default_font_size)
      );
    },
    fontSizes() {
      return fontSizes.filter((s) => s !== "Default (14px)");
    },
    fonts() {
      return fonts;
    },
    currentFont: {
      get() {
        return this.fontFamily;
      },
      set(value) {
        this.fontFamily = value;
        this.$store.commit("UPDATE_DEFAULTS", [
          "default_font_family",
          this.fontFamily,
        ]);
        this.triggerSave();
      },
    },
    currentSize: {
      get() {
        return this.fontSize;
      },
      set(value) {
        this.fontSize = parseInt(value);
        this.$store.commit("UPDATE_DEFAULTS", [
          "default_font_size",
          this.fontSize,
        ]);
        this.triggerSave();
      },
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide("default-font");
    },
    triggerSave() {
      const editor = this.props.source.editor;
      editor.sourceElement.style.fontFamily = this.fontFamily;
      editor.sourceElement.style.fontSize = this.fontSize;
      this.$store.dispatch("saveDefaultFonts", [
        this.currentFont,
        this.currentSize,
        () => {
          this.$toast.open({
            message: "Default Font and Font Size Updated!",
            position: "bottom-left",
            type: "success",
          });
        },
      ]);
    },
  },
  mounted() {
    if (this.getDefaults.default_font_family !== "") {
      this.fontFamily = this.getDefaults.default_font_family;
    }
    if (this.getDefaults.default_font_size !== "") {
      this.fontSize = this.getDefaults.default_font_size;
    }
  },
};
</script>
<style scoped>
.col-form-label {
  font-weight: bold;
}
</style>