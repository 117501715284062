import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Command from '@ckeditor/ckeditor5-core/src/command';


class DefaultFontCommand extends Command {
    execute( callback ) {
        callback();
    }
}

export default class DefaultFontAdapter extends Plugin {
    /**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'DefaultFontAdapter';
	}

    imageIcon() {
        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" xmlns:v="https://vecta.io/nano"><path d="M17.916 8.621a.328.328 0 0 0-.218-.13l-1.906-.291a6.23 6.23 0 0 0-.427-1.021l.562-.734.531-.693a.4.4 0 0 0 .083-.24c0-.097-.024-.174-.073-.229-.25-.354-.823-.945-1.718-1.771-.083-.07-.17-.104-.26-.104a.34.34 0 0 0-.25.094L12.76 4.616a6.439 6.439 0 0 0-.937-.386l-.292-1.916a.31.31 0 0 0-.12-.224.392.392 0 0 0-.255-.089H8.844c-.202 0-.326.097-.375.292-.09.347-.191.993-.302 1.937a5.959 5.959 0 0 0-.948.396L5.782 3.512c-.09-.07-.181-.104-.27-.104-.153 0-.481.248-.984.745l-1.026 1.12a.44.44 0 0 0-.094.24c0 .083.034.166.104.25l1.114 1.437a5.135 5.135 0 0 0-.406.958l-1.937.291c-.076.014-.142.059-.198.135a.4.4 0 0 0-.084.24v2.312a.392.392 0 0 0 .083.245.328.328 0 0 0 .218.13l1.906.282a5.143 5.143 0 0 0 .427 1.031l-.562.734-.531.693a.4.4 0 0 0-.083.24.408.408 0 0 0 .073.239c.271.375.844.958 1.719 1.75.077.077.163.114.26.114.104 0 .191-.031.261-.094l1.469-1.114a6.49 6.49 0 0 0 .937.386l.292 1.916a.31.31 0 0 0 .12.224.392.392 0 0 0 .255.089h2.312c.202 0 .326-.097.375-.292.09-.347.19-.993.302-1.937.326-.104.642-.236.948-.396l1.437 1.125c.097.062.187.094.27.094.153 0 .479-.246.979-.739s.844-.868 1.031-1.125c.062-.07.094-.15.094-.239a.4.4 0 0 0-.104-.261l-1.114-1.437c.139-.257.274-.573.406-.948l1.927-.291c.083-.014.153-.059.208-.135s.083-.157.083-.24V8.868a.392.392 0 0 0-.083-.245zm-6.03 3.265a2.568 2.568 0 0 1-1.885.781 2.568 2.568 0 0 1-1.885-.781A2.568 2.568 0 0 1 7.334 10a2.568 2.568 0 0 1 .781-1.885A2.568 2.568 0 0 1 10 7.334a2.568 2.568 0 0 1 1.885.781A2.568 2.568 0 0 1 12.667 10a2.568 2.568 0 0 1-.781 1.885z"/></svg>`
    }

    init() {
        
        const editor = this.editor;
        const t = editor.t;

        editor.commands.add( 'defaultFontConfig', new DefaultFontCommand( editor ) );

        editor.ui.componentFactory.add( 'defaultfont', locale => {
            
            const command = editor.commands.get( 'defaultFontConfig' );
            
            const view = new ButtonView( locale );

            view.set( {
                label: t('Default Font'),
                icon: this.imageIcon(),
                tooltip: true,
            } );

            view.bind( 'isOn' ).to( command, 'isOn' );

            view.on( 'execute', () => {

                command.execute(() => {});

            } );

            return view;
        } );
    }

    destroy() {
        // Removes all listeners added with this.listenTo();
        this.stopListening();
    }
}