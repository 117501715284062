import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import Command from "@ckeditor/ckeditor5-core/src/command";

class CustomClipboardCommand extends Command {
  constructor(editor, commandKey) {
    super(editor);
    this.commandKey = commandKey;
    this.isEnabled = false;
    this.isOn = false;
  }

  refresh() {
    const model = this.editor.model;
    const doc = model.document;
    const selected = model.getSelectedContent( doc.selection );

    if (this.commandKey === `copy`) {
      this.isEnabled = !selected.isEmpty;
    }
    if (this.commandKey === `cut`) {
      this.isEnabled = !selected.isEmpty;
    }
    if (this.commandKey === `paste`) {
      // this.isEnabled = doc.selection.focus.offset > 0;
      
      const copyCommand = this.editor.commands.get(`copyButton`);
      this.listenTo( copyCommand, 'execute', () => {
        this.isEnabled = true;
      }, { priority: 'low' } );
      const cutCommand = this.editor.commands.get(`cutButton`);
      this.listenTo( cutCommand, 'execute', () => {
        this.isEnabled = true;
      }, { priority: 'low' } );
    }
  }

  selection() {
    const editor = this.editor;
    const selection = editor.model.document.selection;
    const range = selection.getFirstRange();

    let text = '';
    for (const item of range.getItems()) {
        text = text + item.data;
    } 
    return text;
  }

  execute() {
    if (this.commandKey === `copy`) {
      document.execCommand(this.commandKey);
      localStorage.setItem("fjtl_clipboard", this.selection());
    }
    if (this.commandKey === `cut`) {
      document.execCommand(this.commandKey);
      localStorage.setItem("fjtl_clipboard", this.selection());
    }
    if (this.commandKey === `paste`) {
      this.editor.model.change( writer => {
        const insertPosition = this.editor.model.document.selection.getFirstPosition();
        writer.insertText( localStorage.getItem("fjtl_clipboard"), insertPosition );
      });
    }
  }
}

export default class CustomClipboardAdapter extends Plugin {
  /**
   * @inheritDoc
   */
  static get pluginName() {
    return "CustomClipboardAdapter";
  }

  imageIcon(c) {
    const icons = {
      copy:
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" xmlns:v="https://vecta.io/nano"><path d="M53.979 9.143h-3.968c-.083 0-.156.028-.233.047V5.023C49.778 2.253 47.473 0 44.64 0H10.217C7.384 0 5.079 2.253 5.079 5.023v46.843c0 2.77 2.305 5.023 5.138 5.023h6.037v2.268c0 2.671 2.216 4.843 4.941 4.843h32.784c2.725 0 4.941-2.173 4.941-4.843V13.986c0-2.671-2.216-4.843-4.941-4.843zM7.111 51.866V5.023c0-1.649 1.394-2.991 3.106-2.991H44.64c1.712 0 3.106 1.342 3.106 2.991v46.843c0 1.649-1.394 2.991-3.106 2.991H10.217c-1.712 0-3.106-1.342-3.106-2.991zm49.778 7.291c0 1.551-1.306 2.812-2.91 2.812H21.195c-1.604 0-2.91-1.261-2.91-2.812v-2.268H44.64c2.833 0 5.138-2.253 5.138-5.023V11.128c.077.019.15.047.233.047h3.968c1.604 0 2.91 1.261 2.91 2.812v45.171z"/><use xlink:href="#B"/><use xlink:href="#B" y="8.127"/><use xlink:href="#B" y="16.254"/><path d="M28.444 37.587h-12.19c-.562 0-1.016.454-1.016 1.016s.454 1.016 1.016 1.016h12.19c.562 0 1.016-.454 1.016-1.016s-.454-1.016-1.016-1.016z"/><defs ><path id="B" d="M38.603 13.206H16.254c-.562 0-1.016.454-1.016 1.016s.454 1.016 1.016 1.016h22.349c.562 0 1.016-.454 1.016-1.016s-.454-1.016-1.016-1.016z"/></defs></svg>',
      cut:
        '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21.777" fill="#212121" xmlns:v="https://vecta.io/nano"><path d="M6.292 2.39a.889.889 0 0 0-1.475.993l4.26 6.324-2.068 3.269c-.444-.199-.936-.31-1.454-.31a3.556 3.556 0 1 0 3.556 3.556 3.538 3.538 0 0 0-.705-2.126l1.757-2.778 1.675 2.485a3.54 3.54 0 0 0-.949 2.419 3.556 3.556 0 1 0 2.445-3.379l-3.217-4.777-.001.001zM3.778 16.222a1.778 1.778 0 1 1 3.556 0 1.778 1.778 0 1 1-3.556 0zm8.889 0a1.778 1.778 0 1 1 3.556 0 1.778 1.778 0 1 1-3.556 0zm-.427-8.187l2.956-4.672a.889.889 0 0 0-1.502-.95l-2.538 4.012z"/></svg>',
      paste:
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 502 502" xmlns:v="https://vecta.io/nano"><path d="M467.35 190.176l-70.468-70.468c-1.876-1.875-4.419-2.929-7.071-2.929h-23.089V49c0-5.523-4.478-10-10-10h-115v-2.41c0-20.176-16.414-36.59-36.59-36.59h-11.819c-20.176 0-36.591 16.415-36.591 36.59V39h-115c-5.522 0-10 4.477-10 10v386c0 5.523 4.478 10 10 10h146.386v47c0 5.523 4.478 10 10 10h262.171c5.522 0 10-4.477 10-10V197.247a10 10 0 0 0-2.929-7.071zm-67.539-39.255l36.326 36.326h-36.326v-36.326zM144.721 59h47c5.522 0 10-4.477 10-10s-4.478-10-10-10h-15v-2.41c0-9.148 7.442-16.59 16.591-16.59h11.819c9.147 0 16.59 7.442 16.59 16.59V49c0 5.523 4.478 10 10 10h22v20h-109V59zm53.386 57.779c-5.522 0-10 4.477-10 10V425H51.721V59h73v30c0 5.523 4.478 10 10 10h129c5.522 0 10-4.477 10-10V59h73v57.779H198.107zM450.278 482H208.107V136.779H379.81v60.468c0 5.523 4.478 10 10 10h60.468V482zM243.949 253.468h125.402c5.522 0 10-4.477 10-10s-4.478-10-10-10H243.949c-5.522 0-10 4.477-10 10s4.478 10 10 10z"/><use xlink:href="#B"/><use xlink:href="#B" y="50.009"/><use xlink:href="#B" y="100.019"/><path d="M397.767 253.468h16.67c5.522 0 10-4.477 10-10s-4.478-10-10-10h-16.67c-5.522 0-10 4.477-10 10s4.478 10 10 10z"/><defs ><path id="B" d="M414.437 283.478H243.949c-5.522 0-10 4.477-10 10s4.478 10 10 10h170.487c5.522 0 10-4.477 10-10a10 10 0 0 0-9.999-10z"/></defs></svg>',
    };
    return icons[c];
  }

  init() {
    const editor = this.editor;
    const t = editor.t;

    ["copy", "cut", "paste"].map((c) => {
      editor.commands.add(`${c}Button`, new CustomClipboardCommand(editor, c));
      editor.ui.componentFactory.add(`${c}Button`, (locale) => {
        const command = editor.commands.get(`${c}Button`);
        const view = new ButtonView(locale);
        view.set({
          label: t(c),
          icon: this.imageIcon(c),
          // withText: true,
          tooltip: true,
        });
        // view.bind("isOn").to(command, "isOn");
        view.bind("isEnabled").to(command, "isEnabled");
        view.on("execute", () => {
          command.execute();
        });
        return view;
      });
    });
  }

  destroy() {
    // Removes all listeners added with this.listenTo();
    this.stopListening();
  }
}
