import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import Command from "@ckeditor/ckeditor5-core/src/command";

import { normalizeOptions } from "./utils";

import { Collection } from "ckeditor5/src/utils";

import { Model, createDropdown, addListToDropdown } from "ckeditor5/src/ui";

const LINE_SPACING = "lineSpacing";

class LineSpacingCommand extends Command {
  execute({ value }) {
    return value;
  }
}

export default class LineSpacingAdapter extends Plugin {
  /**
   * @inheritDoc
   */
  constructor(editor) {
    super(editor);

    // Define default configuration using named presets.
    editor.config.define(LINE_SPACING, {
      options: [1, 1.15, 1.5, 2],
      active: `1.5rem`,
    });
  }

  /**
   * @inheritDoc
   */
  static get pluginName() {
    return "LineSpacingAdapter";
  }

  imageIcon() {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" preserveAspectRatio="none" xmlns:v="https://vecta.io/nano"><path fill="#5f6368" fill-rule="evenodd" d="M9.743 15.861h9v-2h-9zm0-12v2h9v-2zm-1.5 2l-3.5-3.5-3.5 3.5h2.5v8h-2.5l3.5 3.5 3.5-3.5h-2.5v-8zm1.5 5h9v-2h-9z"/><path d="M3.034 15.59l-1.713-1.713h1.222 1.222V9.861 5.845L2.556 5.83l-1.208-.015 1.7-1.699 1.7-1.699 1.713 1.713 1.713 1.713H6.952 5.73v4.016 4.016l1.208.015 1.208.015-1.7 1.699-1.7 1.699z"/><path d="M9.775 4.86v-.983h4.466 4.466v.983.983h-4.466-4.466z" fill="#010101"/><path d="M9.775 9.86v-.983h4.466 4.466v.983.983h-4.466-4.466zm0 5v-.983h4.466 4.466v.983.983h-4.466-4.466z"/></svg>`;
  }

  init() {
    const editor = this.editor;
    const t = editor.t;

    const options = normalizeOptions(editor.config.get(LINE_SPACING).options);

    editor.commands.add(LINE_SPACING, new LineSpacingCommand(editor));
    const command = editor.commands.get(LINE_SPACING);

    editor.ui.componentFactory.add(LINE_SPACING, (locale) => {
      const dropdownView = createDropdown(locale);

      // set active
      const active = editor.config.get(LINE_SPACING).active ?? `1.5rem`;
      command.set("value", active);

      addListToDropdown(dropdownView, _prepareListOptions(options, command));

      // Create dropdown model.
      dropdownView.buttonView.set({
        label: t("Line Spacing"),
        icon: this.imageIcon(),
        tooltip: true,
      });

      dropdownView.extendTemplate({
        attributes: {
          class: ["ck-line-spacing-dropdown"],
        },
      });

      dropdownView.bind("isEnabled").to(command);

      // Execute command when an item from the dropdown is selected.
      this.listenTo(dropdownView, "execute", (evt) => {
        editor.execute(evt.source.commandName, {
          value: evt.source.commandParam,
        });
        editor.commands.get(LINE_SPACING).set("value", evt.source.commandParam);
        editor.editing.view.focus();
      });

      return dropdownView;
    });
  }

  destroy() {
    // Removes all listeners added with this.listenTo();
    this.stopListening();
  }
}

function _prepareListOptions(options, command) {
  const itemDefinitions = new Collection();

  for (const option of options) {
    const def = {
      type: "button",
      model: new Model({
        commandName: LINE_SPACING,
        commandParam: option.model,
        label: option.title,
        class: "ck-linespacing-option",
        withText: true,
      }),
    };

    if (option.view && option.view.styles) {
      def.model.set(
        "labelStyle",
        `font-size:${option.view.styles["font-size"]}`
      );
    }

    if (option.view && option.view.classes) {
      def.model.set("class", `${def.model.class} ${option.view.classes}`);
    }

    def.model
      .bind("isOn")
      .to(command, "value", (value) => value === option.model);

    // Add the option to the collection.
    itemDefinitions.add(def);
  }

  return itemDefinitions;
}
